/* eslint {eqeqeq:0,no-redeclare:0,no-unused-vars:0,no-loop-func:0} */
import React from "react";
import ReactTable from "react-table";
import 'react-table/react-table.css'
import { Nav, NavItem, NavDropdown, MenuItem, Checkbox, FormGroup, FormControl } from "react-bootstrap";

const htmlInputs={
  test: function(){
    alert("something here coomadnpots");
  },

  renderReacttable: function(prop){
    var frame=prop.frame;
    //var columns=[tableProp.columns]; //react table - counter
    var data=frame.data.data?frame.data.data:[];
    var columns=[frame.data.columns ? frame.data.columns : []];
    var editHeaders=frame.editHeaders?frame.editHeaders:[]; 
    var rowCheckbox=prop.rowCheckbox||""; 
    
    var handleDomChange=prop.handleDomChange; //function to handle state changes
    var toggleRow=prop.toggleRow;
    var toggleCheckAll=prop.toggleCheckAll;

    //react backward applies variable to state. Transfer data to react data.
    var ReactData=data;
    //data.forEach(dataObj => {ReactData.push(dataObj);});
    //var ReactData = data.slice(0);

    //table properties
    var tableProp=frame.tableProp||{};         
    var buttons=tableProp.buttons||[];
    var numRows=tableProp.numRows||15;
    var filterable=tableProp.filterable||false;
    var searchHeaders=tableProp.searchHeaders||[];
    var showPagination=tableProp.showPagination==false?tableProp.showPagination:true;
    

    //if action == edit
    if(frame.action=="edit" || frame.action=="create"){
      //Horizontal Table coding - field & value keys
      for(var i=0; i<editHeaders.length;i++){
      for(var ii=0; ii<ReactData.length;ii++){
        if(editHeaders[i].inputName==ReactData[ii].field){
          var defaultVal=ReactData[ii].value;
          if(frame.action=="create" && !defaultVal){
          defaultVal=editHeaders[i].defaultVal;}
          
          var editType=editHeaders[i].editType;

          var htmlInput="";
          if(editType=="dropdown"){
            htmlInput=htmlInputs.dropdown({
              selection:editHeaders[i].dropdownData.source,
              inputRef:"",
              defaultVal,
              onLoad:handleDomChange,
              onChange:handleDomChange,
              dom_id:editHeaders[i].inputID
            });
          }else if(editType=="boolean"){            
            htmlInput=htmlInputs.dropdown({
              selection:[{name:"Yes",value:1},{name:"No",value:0}],
              inputRef:"",
              defaultVal,
              onLoad:handleDomChange,
              onChange:handleDomChange,
              dom_id:editHeaders[i].inputID
            });
          }else{
          //if(editType=="textbox"){
            htmlInput=htmlInputs.textbox({
              defaultVal,
              onChange:handleDomChange,
              dom_id:editHeaders[i].inputID});
          }
          ReactData[ii].value=htmlInput;

        }
      }}
    }

    
      buttons.forEach(button => {
        //add edit button
        var name=button.name;
        var className=button.class;
        var onclick=button.onclick;
        var data=button.data||{};
        
          columns.unshift({
            id: name,
            width: 80,
            style:{"white-space":"nowrap"},
            accessor: "",
            Cell: ({value}) => (<button className={className} 
              value={JSON.stringify({tableData:value,data})} onClick={onclick}>{name}</button>),        

          });
      });

    //add checkbox
    if(rowCheckbox){
      
      columns.unshift(
        {
          id: "checkbox",
          accessor: "",
          Cell: ({ row }) => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                value={
                  JSON.stringify({
                    id:row[rowCheckbox.primaryID],
                    frameName:rowCheckbox.frameName
                  })
                }
                checked={
                  rowCheckbox.checked.includes(row[rowCheckbox.primaryID])}
                onChange={toggleRow}
              />
            );
          },
          Header: x => {
            return (
              <input
                type="checkbox"
                className="checkbox"
                value={
                  JSON.stringify({
                    frameName:rowCheckbox.frameName
                  })
                }
                checked={rowCheckbox.checkAll}
                ref={input => {
                  if (input) {
                    //input.indeterminate = this.state.selectAll === 2;
                  }
                }}
                onChange={toggleCheckAll}
              />
            );
          },
          sortable: false,
          width: 42
        }
      );
    }

    //adjust column widths
    const getColumnWidth = (rows, accessor, headerText) => {
      const maxWidth = 400
      const magicSpacing = 10
      const cellLength = Math.max(
        ...rows.map(row => (String(row[accessor])||'').length),
        headerText.length,
      )
      var isInput=false;
      rows.forEach(row=>{
        if( typeof row[accessor] === "object" && row[accessor]) {
          isInput=true;
        }
      })
      return (isInput?400:Math.min(maxWidth, cellLength * magicSpacing))
    }

    //loop through column headers
    for(var icol=0;icol<columns.length;icol++){
      if(columns[icol].columns){
        for(var icol2=0;icol2<columns[icol].columns.length;icol2++){
          var column=columns[icol].columns[icol2];
          if(column.accessor){
            //set width
            var width=getColumnWidth(data, column.accessor, column.Header); 
            if(width<71){width+=20;}//6 letter words too small
            columns[icol].columns[icol2]["width"]=width;

            //add filter
            searchHeaders.forEach(s=>{if(s.inputID==column.accessor 
              && s.searchMethod=="dropdown"){
                var inputID=column.accessor;
                var options=[];
                //add options - loop through dataset
                data.forEach(d=>{
                  if(options.indexOf(d[inputID])==-1){
                    options.push(d[inputID]);}})
                var selOptions=[];
                selOptions.push(<option value=""></option>);
                options.forEach(o=>{
                  if(o){selOptions.push(<option value={o}>{o}</option>);}});
                //console.log("data");console.log(data);

                columns[icol].columns[icol2]["Filter"]=({ filter, onChange }) =>
                (<select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >{selOptions}
                </select>)
            }});

          }


        }
      }
    }

    return (
    <div className="table-wrapper">
      <ReactTable
      data={data}
      columns={columns}
      filterable={filterable}
      defaultFilterMethod={(filter, row) =>
        ((String(row[filter.id])).toLowerCase()).indexOf((filter.value).toLowerCase()) >= 0
      }
      minRows="1"
      defaultPageSize={numRows}        
      showPagination={showPagination}
      />
    </div>
    );

    
  },

  textbox: function(prop){
    return (
      <input type="text" defaultValue={prop.defaultVal || ""} 
      id={prop.dom_id}
      onChange={prop.onChange} />
    );
  },
  dropdown: function(prop){
    var dd_selection=[];
    var dd_arr=prop.selection;
    var defaultValue;
    for(var i_dd=0; i_dd<dd_arr.length; i_dd++){
      var dd_name=dd_arr[i_dd]["name"];
      var dd_value=dd_arr[i_dd]["value"];
      if(prop.defaultVal==dd_name){defaultValue=dd_value;}
      if(prop.defaultVal==dd_value){defaultValue=dd_value;}
      dd_selection.push(
        <option value={dd_value}>{dd_name}</option>
      );
    }
    return(
      <FormGroup>
        <FormControl componentClass="select"
        className="input_tb" 
        id={prop.dom_id} 
        onChange={prop.onChange}
        inputRef={prop.inputRef}
        defaultValue={defaultValue}>
        {dd_selection}
        </FormControl>
      </FormGroup>
    );
  },
  button: function(prop){
    var className=prop.class+" myButton ";
    return (
      <span className={className} 
      onClick={prop.func}>{prop.displayName}</span>
    );
  },

  //old DOM model
  input_rows: function(prop){
    var data=prop.model.data;
    var action=prop.action;
    var inputModel=prop.inputModel;
    var buttons_footer=prop.buttons_footer;


    var footer_buttons=[];
    for (var i=0; i<buttons_footer.length;i++){
      if(buttons_footer[i].action==action){
        footer_buttons.push(
        <span class={buttons_footer[i].class} 
        onClick={buttons_footer[i].func}>{buttons_footer[i].displayName}</span>
        );
      }
    }
    
    var input_rows=[];
    if (action=="edit"){ //edit/new      
      var dfield_arr=[]; var inputbox;
      for (var i=0; i<inputModel.length;i++){        
        var input_type="text";        

        var field_value="";
        if(data.length>0){
          var data_obj = data.find(function(d){
            return d.field_id == inputModel[i]["field_id"];});
    
          if(data_obj.input_prop!=undefined){
            input_type=data_obj.input_prop.type;
          }
          field_value=data_obj["field_value"];
        }

        if(input_type=="text" || input_type=="hidden"){
          inputbox=(
            <input 
            type={inputModel[i]["field_type"]} 
            id={inputModel[i]["field_id"]} 
            ref={inputModel[i].inputRef}
            className="input_tb" 
            defaultValue={field_value} />
          );
        }else if(input_type=="checkbox"){
          var is_checked=0;if(field_value==1){is_checked=1;}
          inputbox=(
            <input type="checkbox" 
            defaultChecked={is_checked}
            id={inputModel[i]["field_id"]} 
            ref={inputModel[i].inputRef}
            className="input_cb"  />
          );
        }else if(input_type=="boolean"){
          var dd_selection=[];
          for(var i_dd=0; i_dd<=1; i_dd++){
            dd_selection.push(
              <option value={i_dd}>{i_dd=0?"No":"Yes"}</option>
            );
          }
          inputbox=(
            <FormGroup>
              <FormControl componentClass="select"
              className="input_tb" 
              id={inputModel[i]["field_id"]} 
              //inputRef={this[inputModel[i]["field_id"]]}
              inputRef={inputModel[i].inputRef}
              defaultValue={field_value}>
              {dd_selection}
              </FormControl>
            </FormGroup>
          );
        }else if(input_type=="dropdown"){
          var dd_selection=[];
          var dd_arr=data_obj["input_prop"]["selection"];
          for(var i_dd=0; i_dd<dd_arr.length; i_dd++){
            dd_selection.push(
              <option value={dd_arr[i_dd]["value"]}>{dd_arr[i_dd]["display"]}</option>
            );
          }
          inputbox=(
            <FormGroup>
              <FormControl componentClass="select"
              className="input_tb" 
              id={inputModel[i]["field_id"]} 
              //inputRef={this[inputModel[i]["field_id"]]}
              inputRef={inputModel[i].inputRef}
              defaultValue={field_value}>
              {dd_selection}
              </FormControl>
            </FormGroup>
          );
        }

        var div_class="";if(input_type==="hidden"){div_class="hidden";}
        dfield_arr.push(
          <div className={div_class}>
            <div className="div_fieldname">{inputModel[i]["field_name"]}</div>
            <div className="div_fieldinput">{inputbox}</div>
          </div>
        );
      }
    }else{//view
      var inputbox;
      var dfield_arr=[];var field_val;
      for (var i=0; i<inputModel.length;i++){
        
        var dfield="";
        if(data.length>0){
          var data_obj = data.find(function(d){
          return d.field_id == inputModel[i]["field_id"];});
          dfield=data_obj.field_value;
        }

        dfield_arr.push(
          <div className={div_class}>
            <div className="div_fieldname">{inputModel[i].field_name}</div>
            <div className="div_fieldinput">{dfield}</div>
          </div>
        );
      }
    }

    input_rows.push(
      <div className="div_lineoption">
        {dfield_arr} 
        {footer_buttons}
      </div>);

    return (input_rows);


  },
}
export default htmlInputs;